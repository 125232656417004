import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoginLayout from "../Components/LoginLayout";
import { useHistory } from "react-router-dom";
import {
  eberLogin,
  emptyCustomerDetail,
} from "../../Redux/actions";
import Modal from "../Components/Modal";
import { Terms } from "../Components/Terms";

const UserConfirmation = ({ style, customerType, setCustomerType,userData,setRedirectrPassword }) => {
  var uuid = "not-valid";
  const {eber_details}=useSelector((state)=>state.authDetailReducer)
  let history = useHistory();
  const loaderReducer = useSelector((state) => state.loaderReducer);
  const tableReducer = useSelector((state) => state.tableDetailReducer);

  if(tableReducer?.table_detail?.Merchant?.MerchantLoyaltySetting?.require_password_authentication==="no"){
      
  }


  const merchantId = tableReducer?.table_detail?.Merchant?.id;
  const loyaltyEnabled =
    tableReducer?.table_detail?.Merchant?.loyalty_enabled ?? false;
  const loyaltyPackage =
    tableReducer?.table_detail?.Merchant?.MerchantLoyaltySetting
      ?.LoyaltyPackage ?? null;

  const tableNumber =
    tableReducer?.table_detail?.PosTableMaster?.table_number ?? "";
  const deviceId = uuid;
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authDetailReducer);
  const email = authReducer.email_input;
  const otp = authReducer.otp_input;
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [redirectEberLogin,setRedirectEberLogin]=useState(false)

  // const [customerType, setCustomerType] = useState("");
  const backBtnHandler = () => {
    setCustomerType("");
  };

  const differentUserSubmitHandler = (el) => {
    dispatch(emptyCustomerDetail())
    history.push("/login");
  };

  useEffect(() => {
  if(eber_details)
  history.push(
    `/get_table_details/${tableReducer?.table_detail?.PosTableMaster?.qr_code_id}`
  );
  }, [eber_details]);




  const submitHandler = (el) => {
    el.preventDefault();

      const payload = {
        merchant_id: merchantId ? merchantId : "",
        email: tableReducer?.table_detail?.Customer?.contact_email ?tableReducer?.table_detail?.Customer?.contact_email : "",
        // phone:(tableReducer?.table_detail?.Customer?.contact_number && 
        //  tableReducer.table_detail.Customer.contact_number.startsWith('+91'))
        //   ? tableReducer.table_detail.Customer.contact_number.replace('+91', '')
        //  : tableReducer?.table_detail?.Customer?.contact_number || "",
        // phone:"98688178",
        // user_id:tableReducer?.table_detail?.Customer?.loyalty_member_id??"",
        // external_member_id: tableReducer?.table_detail?.Customer?.eber_member_id ?tableReducer?.table_detail?.Customer?.eber_member_id : "",
        loyaltyType:tableReducer?.table_detail?.Merchant?.MerchantLoyaltySetting?.LoyaltyPackage?.code?
                      tableReducer?.table_detail?.Merchant?.MerchantLoyaltySetting?.LoyaltyPackage?.code:"",
      };
      
      if(!payload.email)
        payload.external_member_id=tableReducer?.table_detail?.Customer?.eber_member_id ?tableReducer?.table_detail?.Customer?.eber_member_id : "";
      
      if(!payload.external_member_id)
        payload.user_id=tableReducer?.table_detail?.Customer?.loyalty_member_id??"";
      

      dispatch(eberLogin(payload));
  };
  return (
    <LoginLayout
      style={style}
      divClass="cart_sidebar"
      backHandler={backBtnHandler}
      showBackBtn={false}
    >
      <div className="login-form pt-1 px_15">
        <h3 className="merchant-title d-flex mt-2 justify-content-center">
        {tableReducer?.table_detail?.Merchant?.account_name}
        </h3>
        <form onSubmit={submitHandler} className="mx-5">
          <div className="group-sec">
            <div
              className="new-customer-text my-5"
              // style={{color:tableReducer?.table_detail?.QrOrdering?.brand_main_color}}
            >
              <h5 className="ml-3 input-title-center ">Is that you, {tableReducer?.table_detail?.Customer?.first_name}? </h5> 
            </div>

            <div
              className="green-box"
              style={{
                paddingBottom:"0",
                width:"20rem",
                display:"flex",
                margin:"auto",
                border: `2px solid ${tableReducer?.table_detail?.QrOrdering?.brand_main_color}`,
              }}
            >
           
                <div className="group" style={{
                     width:"4rem"

                }}>
                   {/* <label for="newCustomer"> */}
                      {/* {" "} */}
                      <span className="existCust" style={{
                          //  size:"2rem"
                      }}></span>
                  </div>

                  <div style={{
                       padding:"2%",
                       width:"20rem",
                      textAlign:"start"

                  }}>
                  <p> 
             <strong>{tableReducer?.table_detail?.Customer?.first_name}</strong> 
              </p>
              <p className="input-title">
             Member ID: {tableReducer?.table_detail?.Customer?.eber_member_id?tableReducer?.table_detail?.Customer?.eber_member_id:tableReducer?.table_detail?.Customer?.id} 
              </p>
                  </div>
            </div>
          </div>
          <div className="resend_btn_row my-4">
            
            <button
              type="submit"
              className="btn green-btn new-green"
              style={{
                backgroundColor:
                  tableReducer?.table_detail?.QrOrdering?.brand_main_color,
              }}
            >
              Yes, that's me
            </button>
          </div>
          
        </form>
        <div className="or" style={{
          marginTop:"10px"
        }}>
          {" "}
          <span> Or </span>{" "}
        </div>
        <div className="guest_login mt-3 mb-80" >
          <a
            href="javascript:void(0)"
            className="btn green-btn new-green"
            onClick={differentUserSubmitHandler}
            style={{
              backgroundColor:
                tableReducer?.table_detail?.QrOrdering?.brand_main_color,
            }}
          >
            No, login as different user
          </a>
        </div>
      </div>

      <Modal
        show={showTermsPopup}
        title={""}
        extraClass={"top50"}
        modalbody={<Terms />}
        OnSubmit={() => setShowTermsPopup(false)}
        submitBtnText="ok"
      />
    </LoginLayout>
  );
};

export default UserConfirmation;

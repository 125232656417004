import { put, takeLatest, select, call } from "redux-saga/effects";
import {
  ORDER_PLACE_DETAIL,
  ORDER_PLACE_DETAIL_RECEIVED,
  EMPTY_ORDER_PLACE_DETAIL,
  CART_DETAIL_STATUS_CHANGED,
  EMPTY_CART_DETAIL,
  LOADING_STATUS,
  GET_ORDER_HISTORY_DETAIL,
  ORDER_HISTORY_DETAIL_RECEIVED,
  EMPTY_ORDER_HISTORY_DETAIL,
  DELETE_RECENT_AND_CART_ORDER,
  EMPTY_VOUCHER_LIST_DETAIL,
  GET_VOUCHER_LIST_DETAIL,
  VOUCHER_LIST_DETAIL_RECEIVED,
  EMPTY_RECENT_ORDER_ID,
  RECENT_ORDER_ID_RECEIVED,
  GET_APPLY_VOUCHER_DETAIL,
  APPLY_VOUCHER_DETAIL_RECEIVED,
  EMPTY_APPLY_VOUCHER_DETAIL,
  AVOCADO_MEMBER_LIST_RECEIVED,
  ADD_DETAIL_CARD_TOKEN,
  EMPTY_REORDER_ORDER_IDS,
  REORDER_ORDER_IDS_RECEIVED,
  POS_SOCKET_ORDER_RECIEVE,
  CHECK_QUANTITY_FAILED,
  CHECK_QUANTITY_SUCCEEDED,
  CHECK_QUANTITY_REQUESTED,
  CHANGE_ORDER_TIP,
  CART_DETAIL_RECEIVED,
  REMOVE_COMBINED_ORDER,
  QUANTITY_CHECK_CART_ITEMS_DETAIL,
  UPDATE_COURSE_LIST,
} from "../actions/types";
import {
  showNotificationWithTimeout,
  changeOrderSocketEmit,
  changeTipDetail,
  changeStatusCartDetail,
  changePosCartDetail,
  emptyPosCartDetail,
  toggleShowCompletePage,
  changeSessionExpiredPage,
  changePaymentDetail,
  checkOpenOrder,
  sendPaymentMail,
  item86DetailRecieved,
  temproryPosData,
} from "../actions";
import {
  avocadoAuthToken,
  checkArray,
  checkNull,
  deepCopy,
  getAllMenuIds,
  getCartSubmittedItems,
  getDeviceId,
  getDeviceName,
  getPosCartSubmittedItems,
  getReOrderItemIds,
  getSessionStorageOrDefault,
  orderHistoryApi,
  parseRound,
} from "../../helpers";
import moment from "moment";
import e from "cors";
// import { push, ref, set } from "firebase/database";
import { firebaseDB,handleSendDataFireBase,push,ref ,set} from "../../Utils/firebase";

const defaultErrorMsg = "Something went wrong! try again later.";
const base_url = process?.env?.REACT_APP_API_URL;

// Advocado API
const ad_api_url = process?.env?.REACT_APP_AVOCADO_API_URL;
const ad_username = process?.env?.REACT_APP_AVOCADO_API_USERNAME;
const ad_password = process?.env?.REACT_APP_AVOCADO_API_PASSWORD;

let myHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
  // "Access-Control-Allow-Origin": "*",
};

function* quantityCheckedDetails(action) {
  yield put({ type: LOADING_STATUS, status: true });
  const cartItems = yield select(
    (store) => store?.cartDetailReducer?.cart_detail
  );
  const table_detail = yield select(
    (store) => store?.tableDetailReducer?.table_detail
  );
  const menuIds = getAllMenuIds(cartItems?.filter((p) => p?.is_added == false));
  const payload = {
    merchant_id: table_detail?.Merchant?.id,
    date: moment().format("yyyy-MM-DD"),
    menu_item_ids: menuIds,
  };

  const json = yield fetch(`${base_url}/qr_code_apis/get_menuitem_quotas`, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("orderPlace failed : " + error.message));
  if (json.status) {
    // yield put({
    //   type: actionTypes.CHECK_QUANTITY_SUCCEEDED,
    // });
    const quotaItems = json?.data ?? [];
    
    yield put({ type: QUANTITY_CHECK_CART_ITEMS_DETAIL, payload: quotaItems });
    if (
      checkArray(quotaItems) &&
      quotaItems.findIndex((p) => p?.is_86_item == "yes" || p?.quota !== "") >
        -1
    ) {
      let errorMsg = "";
      let isErrorShown = false;
      cartItems.map((item) => {
        const prefix = errorMsg !== "" ? " " : "";

        // Standard
        const checkExistIdx = quotaItems.findIndex((p) => {
          
          return (
            item.id == p?.menu_item_id &&
            (p?.is_86_item == "yes" ||
              (p?.quota !== "" && parseInt(p?.quota) < item.quantity))
          );
        });
        if (checkExistIdx > -1) {
          isErrorShown = true;
          const checkQuotaItem = quotaItems[checkExistIdx];
          if (checkQuotaItem?.is_86_item == "yes") {
            errorMsg = errorMsg + prefix + `${item?.pos_name},`;
            // `"${item?.pos_name}" is no longer available for order.`;
          } else if (
            checkQuotaItem?.quota !== "" &&
            parseInt(checkQuotaItem?.quota) < item.quantity
          ) {
            errorMsg =
              errorMsg +
              prefix +
              `Only "${parseInt(
                checkQuotaItem?.quota
              )}" units are available to order for "${
                item?.pos_name
              }" at the moment.`;
          }
        }
        // Bundled Components
        const checkComExistIdx = quotaItems.findIndex(
          (p) =>
            item?.BundledMenuItem &&
            checkArray(item?.BundledMenuItem) &&
            item?.BundledMenuItem.findIndex(
              (bunItem) =>
                bunItem.bundle_menu_item_id == p?.menu_item_id &&
                (p?.is_86_item == "yes" ||
                  (p?.quota !== "" && parseInt(p?.quota) < bunItem.quantity))
            ) > -1
        );
        if (!isErrorShown && checkComExistIdx > -1) {
          isErrorShown = true;
          const checkQuotaItem = quotaItems[checkComExistIdx];
          const bunItemIdx = item?.BundledMenuItem.findIndex(
            (bunItem) =>
              bunItem.bundle_menu_item_id == checkQuotaItem?.menu_item_id &&
              (checkQuotaItem?.is_86_item == "yes" ||
                (checkQuotaItem?.quota !== "" &&
                  parseInt(checkQuotaItem?.quota) < bunItem.quantity))
          );
          if (bunItemIdx > -1) {
            item?.BundledMenuItem?.map((bunItemObj) => {
              if (
                bunItemObj.bundle_menu_item_id == checkQuotaItem?.menu_item_id
              ) {
                if (checkQuotaItem?.is_86_item == "yes") {
                  errorMsg =
                    errorMsg +
                    prefix +
                    `"${bunItemObj?.bundle_menu_item_name}" of ${item?.pos_name} is no longer available for order.`;
                } else if (
                  checkQuotaItem?.quota !== "" &&
                  parseInt(checkQuotaItem?.quota) < bunItemObj.quantity
                ) {
                  errorMsg =
                    errorMsg +
                    prefix +
                    `Only "${parseInt(
                      checkQuotaItem?.quota
                    )}" units are available to order for "${
                      bunItemObj?.bundle_menu_item_name
                    }" of ${item?.pos_name} at the moment.`;
                }
              }
            });
          }
        }
      });

      if (isErrorShown) {
        yield put({
          type: CHECK_QUANTITY_FAILED,
          payload: {
            showError: true,
            errorMsg: errorMsg,
          },
        });
      } else {
        yield put({
          type: CHECK_QUANTITY_SUCCEEDED,
        });
      }
    } else {
      yield put({
        type: CHECK_QUANTITY_SUCCEEDED,
      });
    }
  } else {
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
    yield put({
      type: CHECK_QUANTITY_FAILED,
      payload: { error: json.message },
    });
  }
  yield put({ type: LOADING_STATUS, status: false });
}

function* checkSubmitActiveOrder(action) {
  
  const cart_detail = yield select(
    (state) => state?.cartDetailReducer?.cart_detail
  );
  const recent_order_id = yield select(
    (state) => state?.cartDetailReducer?.recent_order_id
  );

  const is_payment_started = yield select(
    (state) => state?.paymentDetailReducer?.is_payment_started
  );
  const is_payment_cleared = yield select(
    (state) => state?.paymentDetailReducer?.is_payment_cleared
  );
  yield put({ type: LOADING_STATUS, status: true });
  // yield put({ type: EMPTY_RECENT_ORDER_ID });
  let items = cart_detail.filter((p) => p.is_added == false);
  const token = yield select(
    (state) => state?.authDetailReducer?.user_detail?.token
  );
  const server_order_id = yield select(
    (state) => state?.tableDetailReducer?.server_order_id
  );
  const deviceId = yield getDeviceId();
  const servicePeriodExpire = getSessionStorageOrDefault(
    "servicePeriodExpire",
    undefined
  );
  myHeaders.token = token;
  const orderPayload = {
    customer_id: action.customerId ?? "",
    merchant_id: action.merchantId ?? "",
    table_number: action.tableNumber ?? "",
    date: servicePeriodExpire ?? "",
  };
  if (checkNull(server_order_id, false)) {
    delete orderPayload.customer_id;
    orderPayload.order_id = server_order_id;
  }
  const json = yield fetch(`${base_url}/qr_code_apis/get_order_details`, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify(orderPayload),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) =>
      console.log("checkActiveOrder failed : " + error.message)
    );

  if (json?.status) {
    if (json?.data?.open_orders !== null) {
      const orders = json?.data?.open_orders;
      const table_detail = yield select(
        (state) => state?.tableDetailReducer?.table_detail
      );
      if (checkNull(recent_order_id, false)) {
        if (
          checkNull(orders?.order_offline_id, false) === false ||
          (orders?.order_offline_id &&
            recent_order_id != orders?.order_offline_id &&
            !is_payment_cleared)
        ) {
          // yield put(changeSessionExpiredPage(true));
        }
      }

      yield put({
        type: RECENT_ORDER_ID_RECEIVED,
        payload: orders[0]?.order_offline_id ?? "",
      });
      yield put({
        type: UPDATE_COURSE_LIST,
        payload: orders[0]?.course_List ?? [],
      });

      yield put({
        type: CHANGE_ORDER_TIP,
        payload: orders?.tips ?? 0,
      });
      // const orderItems = getCartSubmittedItems(orders[0], table_detail);
      const orderItems = getCartSubmittedItems(orders, table_detail);

      for (let i = 0; i < orderItems.length; i++) {
        if (orderItems[i].hasOwnProperty("is_server_fired")) {
          orderItems[i].is_added =
            orderItems[i].is_server_fired == "yes" || orderItems[i].is_fired
              ? true
              : false;
        } else {
          orderItems[i].is_added = true;
        }
      }
      // debugger;
      if (orderItems?.length > 0) {
        // items = [...items, ...orderItems];
        let filterItems = orderItems.filter(
          (ele, ind) =>
            ind ===
            orderItems.findIndex(
              (elem) => elem.offline_id === ele.offline_id && elem.id === ele.id
            )
        );

        items = [...filterItems];
      }
      // const posItems = getPosCartSubmittedItems(orders[0], table_detail); // TODO payment_type
      const posItems = getPosCartSubmittedItems(orders, table_detail); // TODO payment_type

      if (posItems?.length > 0) {
        yield put(
          changePosCartDetail({
            payment_type: orders[0]?.payment_type ?? [],
            split_type_name: orders[0]?.split_type_name ?? "",
            promotions: orders[0]?.promotions ?? [],
            items: posItems,
            transaction_id: orders[0]?.transaction_id ?? [],
            fiserv_order_id: orders[0]?.fiservorderId ?? [],
          })
        );
        yield put(
          changePaymentDetail({
            paymentOpenOrders: json?.data?.open_orders,
            paymentType: checkArray(orders[0]?.payment_type) ? "split" : "",
            splitPaymentArr: orders[0]?.payment_type ?? [],
            wayToSplit: checkArray(orders[0]?.payment_type)
              ? orders[0]?.payment_type.length
              : 2,
            splitType: checkNull(orders[0]?.split_type_name, false)
              ? orders[0]?.split_type_name == "Split Evenly"
                ? 1
                : orders[0]?.split_type_name == "Split By Item"
                ? 2
                : 3
              : 0,
          })
        );
      } else {
        yield put(emptyPosCartDetail());
      }
    }
    yield put(
      item86DetailRecieved(
        json?.data?.["86_items"] && checkArray(json?.data?.["86_items"])
          ? json?.data?.["86_items"]
          : []
      )
    );
    // if (checkArray(json?.data?.["86_items"])) {
    //   const items_86 = json?.data?.["86_items"];
    //   yield put(item86DetailRecieved(items_86));
    //   // check already 86 items
    //   const items86Ids = pluckArray(items_86, "id");
    //   const already86ItemsName = [];
    //   const already86ItemsId = [];
    //   cart_detail
    //     .filter((p) => p.is_added == false)
    //     .map((cartItem) => {
    //       if (items86Ids.includes(cartItem.id)) {
    //         already86ItemsId.push(cartItem.id);
    //         already86ItemsName.push(cartItem.pos_name);
    //       }
    //     });
    //   if (checkArray(already86ItemsName)) {
    //     yield put(
    //       showItemAvailableModal(
    //         `${already86ItemsName.join(", ")} ${
    //           already86ItemsName.length > 1 ? "are" : "is"
    //         } no longer available, removed from your basket.`
    //       )
    //     );
    //   }
    //   if (checkArray(already86ItemsId)) {
    //     items = items.filter(
    //       (p) => p.is_added == true || !already86ItemsId.includes(p.id)
    //     );
    //   }
    //   // check already 86 items END
    // }
  } else {
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
   
  }
  // yield put({ type: CART_DETAIL_RECEIVED, cart_detail: items });
  yield put({ type: LOADING_STATUS, status: false });
  return json;
}

function* orderPlace(action) {

  const payloadBody = action?.body;
  
  const { pos_cart_detail } = yield select((state) => state.cartDetailReducer);


  yield put({ type: EMPTY_ORDER_PLACE_DETAIL });
  // yield put({ type: EMPTY_RECENT_ORDER_ID });
  yield put({ type: LOADING_STATUS, status: true });
  const token = yield select(
    (state) => state?.authDetailReducer?.user_detail?.token
  );
  const como_details = yield select(
    (state) => state?.authDetailReducer?.como_details
  );
  const user_detail = yield select(
    (state) => state?.authDetailReducer?.user_detail
  );
  const server_user_detail = yield select(
    (state) => state?.authDetailReducer?.server_user_detail
  );
  const is_payment_started = yield select(
    (state) => state?.paymentDetailReducer?.is_payment_started
  );
  const paymentBody = yield select(
    (state) => state?.paymentDetailReducer?.paymentBody
  );
  const mail_order_detail = yield select(
    (state) => state?.paymentDetailReducer?.mail_order_detail
  );
  const cartPreItems = yield select(
    (store) => store?.cartDetailReducer?.cart_detail
  );

  const { eber_details } = yield select((store) => store?.authDetailReducer);

  myHeaders.token = token;

  const userId = checkNull(user_detail?.id, false)
    ? user_detail?.id
    : paymentBody?.qr_customer_id ?? null;

  if (is_payment_started && payloadBody?.order_status === "complete") {
    yield put(
      changePaymentDetail({
        is_payment_cleared: true,
      })
    );
  }

  if (server_user_detail?.id) {
    payloadBody.employee_id = server_user_detail?.id;
  }
  payloadBody.order_type = server_user_detail?.id ? "server" : "qr";

  if (como_details?.membership) {
    payloadBody.loyaltyToken = como_details?.membership?.comoMemberId;
    payloadBody.loyaltyCardNumber = checkNull(
      como_details?.membership?.phoneNumber,
      como_details?.membership?.email
    );
    payloadBody.loyalty_login_type = checkNull(
      como_details?.membership?.phoneNumber,
      false
    )
      ? "mobile"
      : "email";
  }

  //  if(eber_details){
  //   payloadBody.member_tier=eber_details?.member_tiers[0]?.name??"",
  //   payloadBody.point_balance=eber_details?.points[0]?.points??""
  //  }

  myHeaders.token = yield select(
    (state) => state?.authDetailReducer?.authToken
  );
  myHeaders.deviceid = yield getDeviceId();
  

  const json = yield fetch(`${base_url}/qr_code_apis/submitOrder`, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify({
      ...payloadBody,
      member_tier: eber_details?.member_tiers[0]?.name ?? "",
      point_balance: eber_details?.points[0]?.points ?? "",
      channel_name:
        pos_cart_detail?.channel_name == "" ||
        pos_cart_detail?.channel_name == null
          ? "Walk-In"
          : pos_cart_detail?.channel_name,
      reservationType: pos_cart_detail?.reservationType,
      addCustomTableName: pos_cart_detail?.addCustomTableName,
      reservation_id: pos_cart_detail?.reservation_id,
    }),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("orderPlace failed : " + error.message));
  if (json?.status && json?.data) {
    
    if (
      json?.data?.["order_list"]?.[0]?.order_status == "cancelled" ||
      json?.data?.["order_list"]?.[0]?.table_no != payloadBody?.table_no
    ) {
      yield put(changeSessionExpiredPage(true));
    }
    const orderId = json?.data?.["order_list"]?.[0]?.order_offline_id ?? "";
    const cartItems = json?.data?.["order_list"]?.[0]?.menu_item ?? [];
    for (let i = 0; i < cartPreItems.length; i++) {
      if (cartPreItems[i].hasOwnProperty("is_fired")) {
        cartPreItems[i].is_added = true;
      }
      if (cartPreItems[i].hasOwnProperty("is_server_fired")) {
        cartPreItems[i].is_server_fired = "yes";
      }
    }

    // yield put({ type: EMPTY_CART_DETAIL });
    yield put(changeStatusCartDetail(cartPreItems));
    yield put(changePosCartDetail(cartPreItems));
    yield put(temproryPosData(cartItems));
    
    // yield put(changeStatusCartDetail(cartItems))
    // yield put(changePosCartDetail(cartItems))
   
    
    // if (action.onlyTip !== true) {
      //   yield put({ type: CART_DETAIL_STATUS_CHANGED });
      // }
      
    
    //send data on firbase when order created
    const merchantId = user_detail?.merchant_id;
    const offline_id = json?.data?.order_list[0]?.order_offline_id?.replace(/[.#$[\]]/g, "_");
    
    json?.data?.is_new_order==="yes"&& handleSendDataFireBase(
      {
        offline_id,
        orderId:json?.data?.order_list[0]?.order_id,
        user_detail,
        eventName:"Order Created",
        merchantId
      });

    yield put(changeTipDetail(payloadBody?.tips ?? 0));
    yield put({ type: ORDER_PLACE_DETAIL_RECEIVED, payload: json?.data });
    yield put({
      type: RECENT_ORDER_ID_RECEIVED,
      payload: orderId,
    });
    // send Payment Mail
    if (checkNull(mail_order_detail, false) && checkNull(orderId, false)) {
      const copyOrderDetail = deepCopy(mail_order_detail);
      copyOrderDetail.billId = orderId;
      yield put(sendPaymentMail({ order_detail: copyOrderDetail }));
    }
    // send Payment Mail END

    yield put(changeOrderSocketEmit(true));
    if (!is_payment_started && action?.hideToast === false) {
      if (action.onlyTip === true) {
        yield put(
          showNotificationWithTimeout(
            `Tip has been updated to the order successfully`,
            "success"
          )
        );
      } else {
        yield put(
          showNotificationWithTimeout(
            `${json?.message ? json.message : "success"}`,
            "success"
          )
        );
      }
    }
  } else {
    if (is_payment_started && payloadBody?.order_status === "complete") {
      yield put(
        changePaymentDetail({
          is_payment_cleared: false,
        })
      );
    } else {
      yield put(changeSessionExpiredPage(true));
    }
    yield put(
      showNotificationWithTimeout(
        `${json?.message ? json.message : defaultErrorMsg}`,
        "error"
      )
    );
    
  }
  yield put({
    type: CHECK_QUANTITY_FAILED,
    payload: { error: json.message },
  });
  if (payloadBody?.order_status !== "complete" && userId) {
    const submitCheck = yield call(checkSubmitActiveOrder, {
      merchantId: payloadBody?.merchant_id ?? "",
      tableNumber: payloadBody?.table_no ?? "",
      customerId: userId,
    });
   
  }
  yield put({ type: LOADING_STATUS, status: false });
}

function* remove_order(action) {
  myHeaders.token = yield select(
    (state) => state?.authDetailReducer?.authToken
  );
  myHeaders.deviceid = yield getDeviceId();

  const json = yield fetch(`${base_url}/qr_code_apis/submitOrder`, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify(action?.payload),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("orderPlace failed : " + error.message));

  yield put({ type: LOADING_STATUS, status: false });
}

function* orderHistory(action) {
  yield put({ type: EMPTY_REORDER_ORDER_IDS });
  yield put({ type: EMPTY_ORDER_HISTORY_DETAIL });
  yield put({ type: EMPTY_VOUCHER_LIST_DETAIL });
  yield put({ type: LOADING_STATUS, status: true });
  const token = yield select(
    (state) => state?.authDetailReducer?.user_detail?.token
  );
  const customerId = yield select(
    (state) => state?.authDetailReducer?.user_detail?.id
  );
  const user_detail = yield select(
    (state) => state?.authDetailReducer?.user_detail
  );
  const avocadoDetail = yield select(
    (state) => state?.authDetailReducer?.avocado_detail
  );
  const deviceId = yield getDeviceId();
  const orderHistoryData = yield orderHistoryApi({
    deviceId: deviceId,
    token: token,
    merchantId: action?.merchantId ?? "",
    customerId: customerId ?? "",
  });
  if (orderHistoryData?.status && orderHistoryData?.data) {
    yield put({
      type: ORDER_HISTORY_DETAIL_RECEIVED,
      payload: orderHistoryData?.data,
    });
    const reOrderIds = getReOrderItemIds(orderHistoryData?.data);
    if (reOrderIds.length > 0) {
      yield put({ type: REORDER_ORDER_IDS_RECEIVED, payload: reOrderIds });
    }
  } else {
    yield put(
      showNotificationWithTimeout(
        `${
          orderHistoryData?.message ? orderHistoryData.message : defaultErrorMsg
        }`,
        "error"
      )
    );
   
  }
  if (avocadoDetail !== null) {
    const authToken = yield avocadoAuthToken();
    if (authToken?.access_token?.value) {
      myHeaders.Authorization = "Bearer " + authToken?.access_token?.value;
      const memberShipList = yield fetch(`${ad_api_url}/v1.1/memberships`, {
        mode: "cors",
        headers: new Headers(myHeaders),
      })
        .then((response) => response.json())
        .then((json) => json)
        .catch((error) =>
          console.log("avocado_membership_list failed : " + error.message)
        );
      if (
        memberShipList?.code == 200 &&
        memberShipList?.data?.tiers &&
        memberShipList?.data?.tiers.length > 0
      ) {
        yield put({
          type: AVOCADO_MEMBER_LIST_RECEIVED,
          payload: memberShipList?.data?.tiers,
        });
      }
    }
  }
  if (user_detail?.CardNo) {
    myHeaders.token = token;
    const voucherList = yield fetch(`${base_url}/ascentis/get_vouchers`, {
      method: "POST",
      mode: "cors",
      headers: new Headers(myHeaders),
      body: JSON.stringify({
        CardNo: user_detail?.CardNo,
        merchant_id: action?.merchantId ?? "",
      }),
    })
      .then((response) => response.json())
      .then((json) => json)
      .catch((error) => console.log("VOUCHER_LIST failed : " + error.message));
    if (voucherList?.status && voucherList?.data) {
      // yield put({ type: EMPTY_CART_DETAIL });
      yield put({
        type: VOUCHER_LIST_DETAIL_RECEIVED,
        payload: voucherList?.data,
      });
    } else {
      yield put(
        showNotificationWithTimeout(
          `${voucherList?.message ? voucherList.message : defaultErrorMsg}`,
          "error"
        )
      );
      console.log(
        `VOUCHER_LIST error : ${
          voucherList?.message ? voucherList.message : defaultErrorMsg
        }`
      );
    }
  }

  yield put({ type: LOADING_STATUS, status: false });
}

function* redeemVoucher(action) {
  console.log("base_url", base_url);
  console.log("action.body redeemVoucher", action?.voucher);
  // yield put({ type: EMPTY_APPLY_VOUCHER_DETAIL });
  yield put({ type: LOADING_STATUS, status: true });
  const token = yield select(
    (state) => state?.authDetailReducer?.user_detail?.token
  );
  const user_detail = yield select(
    (state) => state?.authDetailReducer?.user_detail
  );
  const cart_detail = yield select(
    (state) => state?.cartDetailReducer?.cart_detail
  );
  const applied_voucher = yield select(
    (state) => state?.cartDetailReducer?.applied_voucher
  );

  const TransactDetailLists =
    cart_detail?.length > 0
      ? cart_detail?.map((item) => {
          return {
            Category_Code: item?.sub_category_id ?? "",
            ItemCode: item?.id ?? "",
            Description: item?.name ?? "",
            Qty: item?.quantity ?? "",
            Price: item?.menu_item_price ?? 0,
            Points: null,
            DiscountPer: 0,
            Nett: item?.total ?? 0,
            LineNo: 1,
          };
        })
      : [];

  // myHeaders.token = token;
  console.log("myHeaders", myHeaders);

  if (user_detail?.CardNo) {
    const getVoucherType = yield fetch(
      `${base_url}/ascentis/get_voucher_type`,
      {
        method: "POST",
        mode: "cors",
        headers: new Headers(myHeaders),
        body: JSON.stringify({
          CardNo: user_detail?.CardNo,
          voucherTypeCode: action?.voucher?.VoucherTypeCode,
          merchant_id: action?.merchantId ?? "",
        }),
      }
    )
      .then((response) => response.json())
      .then((json) => json)
      .catch((error) => console.log("APPLY_VOUCHER failed : " + error.message));
    if (getVoucherType?.data?.access_token) {
      let cardAccToken = getVoucherType?.data?.access_token;
      yield put({
        type: ADD_DETAIL_CARD_TOKEN,
        payload: cardAccToken.replace("Bearer ", ""),
      });
    }
    if (
      getVoucherType?.status &&
      getVoucherType?.data?.VoucherTypeLists &&
      getVoucherType?.data?.VoucherTypeLists.length > 0
    ) {
      const voucherListArr = getVoucherType?.data?.VoucherTypeLists.map(
        (voucherItem) => {
          voucherItem.VoucherNo = action?.voucher?.VoucherNo;
          return voucherItem;
        }
      );
      yield put({
        type: APPLY_VOUCHER_DETAIL_RECEIVED,
        payload:
          applied_voucher.length > 0
            ? [...applied_voucher, ...voucherListArr]
            : voucherListArr,
      });
      const voucherTypeValue = parseFloat(
        getVoucherType?.data?.VoucherTypeLists?.[0].TypeValue ?? 0
      );
      /* NOT IN USE */
      // const PointsRedemption = {
      //   DollarToPointsRatio: "1:10:10:10",
      //   PointsToBeDeducted: voucherTypeValue,
      //   PointsUsage: 2,
      //   GivenRebateValue: 3,
      //   PointsToBeDeductedFromGrace: 0,
      // };
      // const applyVoucherList = yield fetch(`${base_url}/ascentis/sales`, {
      //   method: "POST",
      //   mode: "cors",
      //   headers: new Headers(myHeaders),
      //   body: JSON.stringify({
      //     CardNo: user_detail?.CardNo,
      //     VoucherNo: action?.voucher?.VoucherNo,
      //     TransactDetailLists: TransactDetailLists,
      //     PointsRedemption: PointsRedemption,
      //   }),
      // })
      //   .then((response) => response.json())
      //   .then((json) => json)
      //   .catch((error) =>
      //     console.log("APPLY_VOUCHER failed : " + error.message)
      //   );
      // if (applyVoucherList?.status && applyVoucherList?.data) {
      //   // yield put({ type: EMPTY_CART_DETAIL });
      //   yield put({
      //     type: APPLY_VOUCHER_DETAIL_RECEIVED,
      //     payload: action?.voucher,
      //   });
      // } else {
      //   yield put(
      //     showNotificationWithTimeout(
      //       `${
      //         applyVoucherList?.message
      //           ? applyVoucherList.message
      //           : defaultErrorMsg
      //       }`,
      //       "error"
      //     )
      //   );
      //   console.log(
      //     `APPLY_VOUCHER error : ${
      //       applyVoucherList?.message
      //         ? applyVoucherList.message
      //         : defaultErrorMsg
      //     }`
      //   );
      // }
    } else {
      yield put(
        showNotificationWithTimeout(
          `${
            getVoucherType?.message ? getVoucherType.message : defaultErrorMsg
          }`,
          "error"
        )
      );
      console.log(
        `APPLY_VOUCHER error : ${
          getVoucherType?.message ? getVoucherType.message : defaultErrorMsg
        }`
      );
    }
  }

  yield put({ type: LOADING_STATUS, status: false });
}

function* deletRecentAndCartOrder(action) {
  yield put({ type: LOADING_STATUS, status: true });
  yield put({ type: EMPTY_CART_DETAIL });
  yield put({ type: EMPTY_ORDER_PLACE_DETAIL });
  yield put({ type: EMPTY_RECENT_ORDER_ID });
  yield put({ type: LOADING_STATUS, status: false });
}
function* posOrderRecieveDetail(action) {
  const table_detail = yield select(
    (state) => state?.tableDetailReducer?.table_detail
  );
  const is_payment_started = yield select(
    (state) => state?.paymentDetailReducer?.is_payment_started
  );
  const paymentBody = yield select(
    (state) => state?.paymentDetailReducer?.paymentBody
  );
  yield put({ type: LOADING_STATUS, status: true });
  console.log("order_socket_pos_order", action.pos_order);
  yield put(changeTipDetail(action.pos_order?.tips ?? 0));
  const items = getCartSubmittedItems(action.pos_order, table_detail);
  console.log("ordersssss===>items", items);
  let showUpdatedWarning = false;
  // debugger;
  if (items?.length > 0) {
    yield put(changeStatusCartDetail(items));
  }
  const posItems = getPosCartSubmittedItems(action.pos_order, table_detail);
  if (is_payment_started) {
    if (
      (checkArray(paymentBody?.menu_item) &&
        checkArray(posItems?.length) &&
        posItems?.length !== paymentBody?.menu_item?.length) ||
      parseRound(paymentBody?.finalTotalAmt) !==
        parseRound(action?.pos_order?.finalTotalAmt) ||
      paymentBody?.tips !== action?.pos_order?.tips
    ) {
      showUpdatedWarning = true;
    }
  }

  if (posItems?.length > 0) {
    yield put(
      changePosCartDetail({
        payment_type: action.pos_order?.payment_type ?? [],
        split_type_name: action.pos_order?.split_type_name ?? "",
        promotions: action.pos_order?.promotions ?? [],
        items: posItems,
        transaction_id: action.pos_order?.transaction_id ?? [],
        fiserv_order_id: action.pos_order?.fiservorderId ?? [],
      })
    );
    yield put(
      changePaymentDetail({
        paymentType: checkArray(action.pos_order?.payment_type) ? "split" : "",
        splitPaymentArr: action.pos_order?.payment_type ?? [],
        wayToSplit: checkArray(action.pos_order?.payment_type)
          ? action.pos_order?.payment_type.length
          : 2,
        splitType: checkNull(action.pos_order?.split_type_name, false)
          ? action.pos_order?.split_type_name == "Split Evenly"
            ? 1
            : action.pos_order?.split_type_name == "Split By Item"
            ? 2
            : 3
          : 0,
      })
    );
  } else {
    yield put(emptyPosCartDetail());
  }
  if (action.pos_order?.order_status == "complete") {
    yield put(toggleShowCompletePage(true));
  } else if (
    (action.pos_order?.table_no !==
      table_detail?.PosTableMaster?.table_number &&
      !is_payment_started) ||
    action.pos_order?.order_status == "cancelled"
  ) {
    console.log("ordersssss===>Session Expireddd");
    yield put(changeSessionExpiredPage(true));
  } else if (showUpdatedWarning) {
    yield put(
      showNotificationWithTimeout(
        `Warning! order has been updated through POS`,
        "error"
      )
    );
  }
  yield put({ type: LOADING_STATUS, status: false });
}

export default function* cartDetailActionWatcher() {
  yield takeLatest(ORDER_PLACE_DETAIL, orderPlace);
  yield takeLatest(GET_ORDER_HISTORY_DETAIL, orderHistory);
  yield takeLatest(GET_APPLY_VOUCHER_DETAIL, redeemVoucher);
  yield takeLatest(DELETE_RECENT_AND_CART_ORDER, deletRecentAndCartOrder);
  yield takeLatest(POS_SOCKET_ORDER_RECIEVE, posOrderRecieveDetail);
  yield takeLatest(CHECK_QUANTITY_REQUESTED, quantityCheckedDetails);
  yield takeLatest(REMOVE_COMBINED_ORDER, remove_order);
}
